import React, {FC} from 'react';
import Heading from 'src/components/Heading/Heading';
import HeadingStyle from 'src/components/Heading/HeadingStyle.enum';
import {GapAnalysisBar} from 'src/components/GapAnalysis/GapAnalysisBar/GapAnalysisBar';
import Breakpoint from '../../types/BreakPoint.enum';
import {useScreenSize} from 'src/hooks/useScreenSize';
import styles from './PolicyComponentQuestions.module.scss';
import {Box} from '@mui/material';
import {PolicyAnswer} from 'src/types/PolicyAnswer';
import {PolicyQuestion} from 'src/types/PolicyQuestion';
import {PolicyQuestionsLegend} from '../PolicyQuestionsLegend/PolicyQuestionsLegend';

interface PolicyComponentQuestionsProps {
  title: string;
  answers: PolicyAnswer[];
  questions: PolicyQuestion[];
  totalYesAnswers: number;
}

export const PolicyComponentQuestions: FC<PolicyComponentQuestionsProps> = ({
                                                                              title,
                                                                              answers,
                                                                              questions,
                                                                              totalYesAnswers,
                                                                            }) => {
  const {screenSizeIsAtLeast} = useScreenSize();


  const numbers = ['one', 'two', 'three', 'four', 'five', 'six'];
  const filler = [...Array(6 - questions.length)].map((_, index: number) => (
    <div key={'filler-' + index} className={styles.question}>
      <div className={styles.numberAndStatusContainer}>
        <span className={styles.grayedNumber}>{questions.length + index + 1}</span>
        <span className={styles.notApp}>N/A</span>
      </div>
      <div className={styles.questionText}>This policy only has {numbers[questions.length - 1]} components.</div>
    </div>
  ));

  const lastModifiedDate = questions.reduce(
    (prev: Date, curr: PolicyQuestion) =>
      new Date(curr.subcategory?.lastModifiedDate) > prev ? new Date(curr.subcategory?.lastModifiedDate) : prev,
    new Date('1970-01-01 00:00:01'),
  );

  const findAnswerForQuestion = (questionId: number): PolicyAnswer | undefined => {
    return answers.find((answer) => answer.question.id === questionId);
  };

  return (
    <Box className={styles.policyComponentQuestionsSection}>
      <div className={styles.gapAnalysisBarContainer}>
        <GapAnalysisBar
          yes={totalYesAnswers}
          total={questions.length}
          height="70px"
          width="90%"
          fontSize={screenSizeIsAtLeast[Breakpoint.MD] ? '1.375rem' : '1rem'}
        />
      </div>
      <Box className={styles.questionsContainer}>
        <Heading headingStyle={HeadingStyle.CONTAINER_SUBTITLE}>
          Policy Components Questions - <span className={styles.emphasized}>{title}</span>
        </Heading>
        {questions.map((question: PolicyQuestion, index: number) => {
          const correspondingAnswer = findAnswerForQuestion(question.id);
          const isYes = correspondingAnswer?.value || false; // Check if the answer is "yes"

          return (
            <div key={index} className={styles.question}>
              <div className={styles.numberAndStatusContainer}>
                <span className={styles.number}>{index + 1}</span>
                {isYes ? <span className={styles.yes}>Y</span> : <span className={styles.no}>N</span>}
              </div>
              <div className={styles.questionTextContainer}>
                <div className={styles.questionText}>
                  {question.text}
                  {correspondingAnswer?.url && (
                    <>
                      <span className={styles.line}>|</span>
                      <a href={urlStringWithProtocol(correspondingAnswer.url)} target="_blank" rel="noreferrer">
                        View Source&nbsp;&gt;
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {filler}
        <PolicyQuestionsLegend lastUpdatedDate={lastModifiedDate}/>
      </Box>
    </Box>
  );
};

function urlStringWithProtocol(url?: string) {
  if (!url) return '#';
  return url.includes('http') ? url : 'http://' + url;
}
