import {useQuery} from '@apollo/client';
import {AccordionDetails, Alert, CircularProgress} from '@mui/material';
import {plainToInstance} from 'class-transformer';
import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {AdminButton} from 'src/components/AdminButton/AdminButton';
import SubcategoryAccordion from 'src/components/SubcategoryAccordion/SubcategoryAccordion';
import {PolicyAnswer} from 'src/types/PolicyAnswer';
import {PolicyAnswerInput} from 'src/types/PolicyAnswerInput';
import {PolicyCategory} from 'src/types/PolicyCategory';
import {PolicyQuestion} from 'src/types/PolicyQuestion';
import {PolicySubcategory} from 'src/types/PolicySubcategory';
import {getState, State} from 'src/types/State.enum';
import {POLICY_DATA} from '../../../const/PolicyData.graphql';
import {AccordionSummary, MainAccordion} from '../DataAdminAccordionTheme';
import styles from './StatePolicyAreaAccordion.module.scss';
import {filterNumOfYes} from "../../../utils/filterNumOfYes";

interface StatePolicyAreaAccordionProps {
  stateName: string;
  policyAnswers: PolicyAnswerInput[];
  setPolicyAnswers: Dispatch<SetStateAction<PolicyAnswerInput[]>>;
  handleSave: (e: any) => void;
  saving: boolean;
  errors: number[],
  setErrors: Dispatch<SetStateAction<number[]>>;
}

export const StatePolicyAreaAccordion: FC<StatePolicyAreaAccordionProps> = ({
                                                                              stateName,
                                                                              policyAnswers,
                                                                              setPolicyAnswers,
                                                                              handleSave,
                                                                              saving,
                                                                              errors,
                                                                              setErrors
                                                                            }) => {
  const {data, loading, refetch, error} = useQuery(POLICY_DATA, {
    variables: {stateId: getState(stateName)},
  });

  useEffect(() => {
    if (data?.getPolicyAnswersByStateId?.length > 0) {
      setPolicyAnswers(
        data.getPolicyAnswersByStateId.map((answer: PolicyAnswer) => plainToInstance(PolicyAnswerInput, answer)),
      );
    } else if (data?.listPolicyQuestions?.length > 0) {
      setPolicyAnswers(
        data.listPolicyQuestions.map((question: PolicyQuestion) => {
          const a = new PolicyAnswerInput();
          a.question = question;
          a.stateId = State[getState(stateName)];
          a.changed = true;
          return a;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    refetch();
  }, [saving, refetch]);

  if (loading) return <CircularProgress/>;
  if (error || data?.listPolicyQuestions?.length === 0) {
    return (
      <Alert variant="outlined" severity="warning">
        Unable to load State Policy information. Please try again and contact support if the issue persists.
      </Alert>
    );
  }

  // TODO: In hindsight, this will need to be updated after users are added to spot.
  return data.listPolicyCategories.map((category: PolicyCategory) => (
    <MainAccordion
      className={styles.mainAccordion}
      key={category.title + category.id}
      TransitionProps={{unmountOnExit: true}}
    >
      <AccordionSummary>
        <div className={styles.summaryContent}>{category.title}</div>
        <div className={styles.lastEditedBy}>
          Last edited on{' '}
          {category.lastModifiedDate
            ? new Date(category.lastModifiedDate).toDateString()
            : new Date(category.createdDate).toDateString()}
        </div>
        <AdminButton text="Save" overwriteUnderline={true} onClick={handleSave} defaultStyle={true} disabled={saving}/>
      </AccordionSummary>
      <AccordionDetails className={styles.mainAccordionDetails}>
        {data.listActivePolicySubcategories
          .filter((s: PolicySubcategory) => s.category.id === category.id)
          .map((subcategory: PolicySubcategory) => {
            const questions = data.listPolicyQuestions
              .filter((question: PolicyQuestion) => question.subcategory.id === subcategory.id)
              .sort((a: PolicyQuestion, b: PolicyQuestion) => a.id - b.id);


            const answers = policyAnswers
              .filter((answer: PolicyAnswerInput) =>
                filterNumOfYes({
                  answer: answer?.question?.subcategory?.id,
                  subcategory: subcategory?.id,
                  questionText: answer.question.text,
                })
              )
              .sort((a: PolicyAnswerInput, b: PolicyAnswerInput) => a.question.id - b.question.id);

            return (
              <SubcategoryAccordion
                key={subcategory.title + subcategory.id}
                subcategory={subcategory}
                questions={questions}
                answers={answers}
                policyAnswers={policyAnswers}
                setPolicyAnswers={setPolicyAnswers}
                handleSave={handleSave}
                saving={saving}
                errors={errors}
                setErrors={setErrors}
              />
            );
          })}
      </AccordionDetails>
    </MainAccordion>
  ));
};
